import {
    BalButton,
    BalButtonGroup,
    BalCard,
    BalCardContent,
    BalHeading,
    BalStage,
    BalStageBody,
    BalStageImage,
    BalText,
} from "@baloise/ds-react";
import { useMediaQuery } from "@lg-cfa/hooks";
import { graphql, navigate } from "gatsby";
import * as React from "react";
import { useTranslation } from "react-i18next";

import HeaderImageMobile from "@images/header-image-mobile.jpeg";
import headerImage from "@images/header-image.jpg";
import { Footer } from "@modules/shared/components";
import { Application } from "@modules/shared/enums";
import { useResetFlow } from "@modules/shared/hooks";
import { PageProps } from "@modules/shared/types";
import { SimulatorLayout } from "@modules/simulator";

const ThankYouPage: React.FC<PageProps> = ({ pageContext }) => {
    const { foundPage: page, ownPageObjects: allPages, language } = pageContext;
    const { resetFlow } = useResetFlow(
        allPages,
        language,
        Application.Simulator,
    );
    const isMobile = useMediaQuery("(max-width: 500px)");

    const { t } = useTranslation();

    return (
        <>
            <SimulatorLayout
                title={t("simulator.thankyou.contact.title")}
                page={page}
                allPages={allPages}
                language={language}
                hideHelp={true}
            >
                <BalStage className="header-image">
                    <BalStageImage
                        className="image"
                        srcSet={isMobile ? HeaderImageMobile : headerImage}
                    ></BalStageImage>
                    <BalStageBody>
                        <div></div>
                    </BalStageBody>
                </BalStage>
                <main className="container is-compact mt-large flex flex-direction-column">
                    <div className="flex-1">
                        <div className="intro">
                            <section className="">
                                <BalHeading level="h1">
                                    {t("simulator.thankyou.help.title")}
                                </BalHeading>
                                <BalText>
                                    {t("simulator.thankyou.help.intro")}
                                </BalText>
                            </section>
                        </div>
                        <BalCard>
                            <BalCardContent>
                                <BalHeading level="h3">
                                    {t("simulator.thankyou.help.card.title")}
                                </BalHeading>
                                <ul className="list has-bullet-check mt-large mb-large">
                                    <li>
                                        {t(
                                            "simulator.thankyou.contact.card.list.one",
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            "simulator.thankyou.contact.card.list.two",
                                        )}
                                    </li>
                                </ul>
                                <BalButtonGroup>
                                    <BalButton
                                        rounded
                                        onClick={() => navigate("/")}
                                    >
                                        {t(
                                            "simulator.thankyou.help.card.buttons.site",
                                        )}
                                    </BalButton>
                                    <BalButton
                                        rounded
                                        color="info"
                                        onClick={() => resetFlow()}
                                    >
                                        {t(
                                            "simulator.thankyou.help.card.buttons.reset",
                                        )}
                                    </BalButton>
                                </BalButtonGroup>
                            </BalCardContent>
                        </BalCard>
                    </div>
                    <Footer />
                </main>
            </SimulatorLayout>
        </>
    );
};

export default ThankYouPage;

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
